import React, {useState, useEffect} from 'react'
import styles from './Input.module.scss'
import Icon from '../icon/Icon';

const Input = ({type, label, icon, onChange, value, name, min, max, noSpacing, autocomplete}) => {
  const [inputEmpty, setInputEmpty] = useState(true);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    value ? setInputEmpty(false) : setInputEmpty(true)
    return () => {
      setInputEmpty(true)
    }
  }, [value])

  // The flashing of box shadow's when you hard refresh happens because the chrome
  // autofill stuff loads after the component renders, so we need to delay transitions
  // to avoid any flashing (setLoading(false) & try) if you want to see the problem this solves
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return setLoading(true);
  }, []);

  return (
    <>
      <div
        className={`${styles.container} ${isLoading ? styles.preload : ""} ${inputEmpty ? styles.inputEmpty : styles.inputFilled} ${noSpacing && styles.noSpacing}`}>
        <label className={styles.label}>
          {icon && <span className={styles.icon}><Icon name={icon} color="#449330" /></span>}
          <input
            {...(type && {type: type})}
            {...(label && {placeholder: label})}
            {...(min && {min: min})}
            {...(max && {max: max})}
            {...(name && {name: name})}
            {...(autocomplete && {autocomplete: autocomplete})}
            className={`${styles.input} ${icon ? styles.inputIcon : ''}`}
            value={value || ""}
            onChange={onChange}
          />
          <span className={styles.labelText}>{label}</span>
        </label>
      </div>
    </>
  );
}

export default Input;
