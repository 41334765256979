import {createContext, useReducer, useEffect, useState} from 'react';
import { useAuth } from "../hooks/useAuth";
import useFirebase from '../hooks/useFirebase';
import { useHistory, useLocation } from "react-router-dom";

export const ProjectContext = createContext();


const reducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.payload]
    case "MODIFY":
      return state.map( item => item.id === action.payload.id ? { ...item, ...action.payload.data } : item )
    case "REMOVE":
      return state.filter(item => item.id !== action.payload.id)
    case "RESET":
      return []
    default:
      return state;
  }
}

// And all this in real time
// u pay on change anyway there is no point in not being all real time (https://www.youtube.com/watch?v=3aoxOtMM2rc)
const ProjectProvider = ({children}) => {
    const [isLoading, setLoading] = useState(true);
    const { firestore } = useFirebase();
    const auth = useAuth();
    const [projects, dispatch] = useReducer(reducer, [])
    const history = useHistory();
    const location = useLocation();

  useEffect(() => {
    if (!auth.user) {
      history.push({
        pathname: '/',
        search: `?destinationPath=${location.pathname}`
      })
      return
    }
    const unsubscribe = firestore.collection("projects").where("author_uid", "==", auth.user.uid)
    .onSnapshot((snapshot) => {
        setLoading(false)
        snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              dispatch({type: "ADD", payload: {id: change.doc.id, ...change.doc.data()}})
            }
            if (change.type === "modified") {
              dispatch({type: "MODIFY", payload: {id: change.doc.id, data: change.doc.data()}})
            }
            if (change.type === "removed") {
              dispatch({type: "REMOVE", payload: {id: change.doc.id}})
            }
        });
    });

    return () => {
      // Unsubscribe from listening
      unsubscribe()
      // Reset state to avoid duplicates
      dispatch({type: "RESET"})
    }
  }, [])

  return(
    <ProjectContext.Provider value={{projects, isLoading}}>
      {children}
    </ProjectContext.Provider>
  )
}

export default ProjectProvider;
