import {useEffect, useState} from 'react';

const fontSizes = {
  "1": "2.2rem",
  "2": "2.4rem",
  "3": "2.6rem",
  "4": "2.8rem",
  "5": "3.0rem",
  "6": "3.2rem",
  "7": "3.4rem",
  "8": "3.6rem",
  "9": "3.8rem",
  "10": "4.0rem",
  "11": "4.2rem",
  "12": "4.4rem",
  "13": "4.6rem",
  "14": "4.8rem",
  "15": "5.0rem",
  "16": "5.2rem",
  "17": "5.4rem",
  "18": "5.6rem",
  "19": "5.8rem",
  "20": "6.0rem",
  "21": "6.2rem",
  "22": "6.4rem",
  "23": "6.6rem",
  "24": "6.8rem",
  "25": "7.0rem",
  "26": "7.2rem",
  "27": "7.4rem",
  "28": "7.6rem",
  "29": "7.8rem",
  "30": "8.0rem",
  "31": "8.2rem",
  "32": "8.4rem",
  "33": "8.6rem",
  "34": "8.8rem",
  "35": "9.0rem",
  "36": "9.2rem",
  "37": "9.4rem",
  "38": "9.6rem",
  "39": "9.8rem",
  "40": "10.0rem",
  "41": "10.2rem",
  "42": "10.4rem",
  "43": "10.6rem",
  "44": "10.8rem",
  "45": "11.0rem",
  "46": "11.2rem",
  "47": "11.4rem",
  "48": "11.6rem",
  "49": "11.8rem",
  "50": "12.0rem",
  "51": "12.2rem",
  "52": "12.4rem",
  "53": "12.6rem",
  "54": "12.8rem",
  "55": "13.0rem",
  "56": "13.2rem",
  "57": "13.4rem",
  "58": "13.6rem",
  "59": "13.8rem",
  "60": "14.0rem",
  "61": "14.2rem",
  "62": "14.4rem",
  "63": "14.6rem",
  "64": "14.8rem",
  "65": "15.0rem",
  "66": "15.2rem",
  "67": "15.4rem",
  "68": "15.6rem",
  "69": "15.8rem",
  "70": "16.0rem",
  "71": "16.2rem",
  "72": "16.4rem",
  "73": "16.6rem",
  "74": "16.8rem",
  "75": "17.0rem",
  "76": "17.2rem",
  "77": "17.4rem",
  "78": "17.6rem",
  "79": "17.8rem",
  "80": "18.0rem",
  "81": "18.2rem",
  "82": "18.4rem",
  "83": "18.6rem",
  "84": "18.8rem",
  "85": "20.0rem",
  "86": "20.2rem",
  "87": "20.4rem",
  "88": "20.6rem",
  "89": "20.8rem",
  "90": "21.0rem",
  "91": "21.2rem",
  "92": "21.4rem",
  "93": "21.6rem",
  "94": "21.8rem",
  "95": "22.0rem",
  "96": "22.2rem",
  "97": "22.4rem",
  "98": "22.6rem",
  "99": "22.8rem",
  "100": "23.0rem"
}

const useFontStyle = ({
  fontFamily,
  color,
  fontWeight,
  textAlign,
  fontSize,
  lineHeight,
  letterSpacing
}) => {

  const [fontStyle, setFontStyle] = useState(null);

  const handleFontSize = () => {
    if(fontSize) {
      return fontSizes[fontSize]
    } else {
      return fontSizes[0]
    }
  }

  // Line height is added to font size (fontsize+lineheight) for consistency
  const handleLineHeight = () => {
    if (lineHeight) {
      return `${Number(parseFloat(fontSizes[fontSize].replace("rem", "").replace(".", "")).toFixed(2)) + lineHeight}px`
    } else if (lineHeight) {
      // Fallback if no fontsize exists
      return `${Number(parseFloat(fontSizes[0].replace("rem", "").replace(".", "")).toFixed(2)) + lineHeight}px`
    } else {
      // since 0 is false then setting 0 is normal
      return "normal"
    }
  }

  const handleLetterSpacing = () => {
    if (letterSpacing) {
      return `${letterSpacing}px`;
    } else {
      // since 0 is false then setting 0 is normal
      return "normal"
    }
  }

  useEffect(() => {
    setFontStyle({
      fontFamily,
      color,
      fontWeight,
      textAlign,
      fontSize: handleFontSize(),
      lineHeight: handleLineHeight(),
      letterSpacing: handleLetterSpacing(),
    })
  },[
    fontFamily,
    color,
    fontWeight,
    textAlign,
    fontSize,
    lineHeight,
    letterSpacing
  ])

  return fontStyle;

}

export default useFontStyle;