import React, {useState, useRef} from 'react';
import styling from "./DropDown.module.scss";
import useOnClickOutside from "../hooks/useOnClickOutside";

const DropDown = ({children, trigger, direction}) => {
  const [open, setOpen] = useState(false);
  const dropRef = useRef();
  useOnClickOutside(dropRef, () => setOpen(false));

  return(
    <div ref={dropRef} className={styling.dropdown}>
      <div onClick={() => setOpen(!open)} className={open ? styling.dropdownTriggerOpen : ""}>{trigger}</div>
      <div
        style={direction && direction === "left" ? {right: '0'} : {left: '0'}}
        className={`${styling.dropdownMenu} ${open ? styling.dropdownMenuOpen : ""}`}
      >
        {children}
      </div>
    </div>
  );
}

export default DropDown;
