import React from 'react';
import styles from './Icon.module.scss'

export default function Icon({name, color, size, style}){
  const styling = {
    color: color ? color : '#111',
    fontSize: size ? size : '2.4rem',
  }
  return (
    <>
      {name ? <i style={{...styling, ...(style && style)}} className={styles.materialIcons}>{name}</i> : '"name" prop undefined' }
    </>
  );
}