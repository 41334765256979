import React from 'react';
import firebase from "firebase";
import "firebase/auth";

firebase.initializeApp({
  apiKey: "AIzaSyBba1DM-YerfKjCd-zRz3i4t6Tfs4DxUPs",
  authDomain: "teleprompter-46fa2.firebaseapp.com",
  projectId: "teleprompter-46fa2",
  storageBucket: "teleprompter-46fa2.appspot.com",
  messagingSenderId: "1098459349861",
  appId: "1:1098459349861:web:132af5ad4dd0e6cd31178c",
  measurementId: "G-WZ52PHGK3W"
});

const useFirebase = () => {
  const firestore = firebase.firestore();

  return {
    firebase,
    firestore
  }
}

export default useFirebase;