import React, {useState} from 'react';
import Navigation from '../navigation';
import Button from '../button'
import Icon from "../icon/Icon";
import styles from "./Account.module.scss";
import { useAuth } from "../hooks/useAuth";
import Input from '../form/Input';
import { useNotification } from '../notification/NotificationProvider';

const Account = () => {
  const auth = useAuth();
  const [email, setEmail] = useState(auth.user.email);
  const [currentPassword, setCurrentPassword] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [newPass, setnewPass] = useState("");
  const [newPassAgain, setnewPassAgain] = useState("");
  const dispatch = useNotification();

  const reauthenticate = (currentPassword, action) => {
    const user = auth.user;
    const cred = auth.credentials(auth.user.email, currentPassword);

    user.reauthenticateWithCredential(cred).then(() => {
      // User re-authenticated.
      // call whatever function requires reauth
      action();
    }).catch((error) => {
      // An error happened.
      dispatch({
        type: "ERROR",
        message: `Error reauthenticating: ${error}`,
      });
    });
  }

  const handleEmailUpdate = () => {
    auth.user.updateEmail(email).then(function() {
      dispatch({
        type: "SUCCESS",
        message: "Successfully updated your email",
      });
      setCurrentPassword("")
    }).catch(function(error) {
      dispatch({
        type: "ERROR",
        message: `Error updating email: ${error}`,
      });
    });
  }

  const handlePasswordUpdate = () => {
    auth.user.updatePassword(newPass).then(function() {
      dispatch({
        type: "SUCCESS",
        message: "Successfully updated your password",
      });
    }).catch(function(error) {
      dispatch({
        type: "ERROR",
        message: `Error updating password: ${error}`,
      });
    });
  }

  return (
    <>
      <Navigation style={{ justifyContent: 'flex-start' }}>
        <>
          <Button style={{width: 'auto', textTransform: 'uppercase', fontWeight: 500}} text="Your projects" color="secondary" to="/" />
          <Icon name="chevron_right" color="#fff" size="4.2rem" />
          <h1>Account</h1>
        </>
      </Navigation>
      <div className={styles.container}>
        <div className={styles.section}>
          <h1 className={styles.title}>Change email</h1>
          <Input
            icon="email"
            label="Your E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autocomplete="email"
          />
          <Input
            icon="lock"
            type="password"
            label="Your current password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            autocomplete="off"
          />
          <Button
            text="Update e-mail"
            onClick={() => reauthenticate(currentPassword, handleEmailUpdate)}
            color="primary"
          />
        </div>
        <div className={styles.section}>
          <h1 className={styles.title}>Change password</h1>
          <Input
            icon="lock"
            type="password"
            label="Your old password"
            value={oldPass}
            onChange={(e) => setOldPass(e.target.value)}
            autocomplete="off"
          />
          <Input
            icon="lock"
            type="password"
            label="New pasword"
            value={newPass}
            onChange={(e) => setnewPass(e.target.value)}
            autocomplete="off"
          />
          <Input
            icon="lock"
            type="password"
            label="New password again"
            value={newPassAgain}
            onChange={(e) => setnewPassAgain(e.target.value)}
            autocomplete="off"
          />
          <Button
            text="Update password"
            onClick={() => reauthenticate(oldPass, handlePasswordUpdate)}
            color="primary"
          />
        </div>
        <div className={styles.section}>
          <h1 className={styles.title}>Subscription</h1>
          <p className={styles.text}>FREE</p>
        </div>
      </div>
    </>
  )
}

export default Account;