import React from 'react';
import styles from './Text.module.scss';
import fontSizes from './fontSizes.json';

const Text = React.forwardRef((props, ref) => {
  const {
    children,
    fontSize,
    letterSpacing,
    lineHeight,
    fontFamily,
    fontWeight,
    textAlign,
    color,
    isFlipped,
    style
  } = props;

  const handleFontSize = () => {
    if(fontSize) {
      return fontSizes[fontSize]
    } else {
      return fontSizes[0]
    }
  }

  // Line height is added to font size
  const handleLineHeight = () => {
    if (fontSize && lineHeight) {
      return `${Number(parseFloat(fontSizes[fontSize].replace("rem", "").replace(".", "")).toFixed(2)) + lineHeight}px`
    } else if (lineHeight) {
      return `${Number(parseFloat(fontSizes[0].replace("rem", "").replace(".", "")).toFixed(2)) + lineHeight}px`
    } else {
      // since 0 is false then setting 0 is normal
      return "normal"
    }
  }

  const handleLetterSpacing = () => {
    if (letterSpacing) {
      return `${letterSpacing}px`;
    } else {
      // since 0 is false then setting 0 is normal
      return "normal"
    }
  }

  return (
    <p
      className={styles.text}
      style={{
        ...(style && {...style}),
        fontFamily: fontFamily || "",
        color: color || "",
        fontWeight: fontWeight || "",
        textAlign: textAlign || "",
        fontSize: handleFontSize(),
        lineHeight: handleLineHeight(),
        letterSpacing: handleLetterSpacing(),
        transform: isFlipped ? 'scale(1, -1)' : 'none',
      }}
      ref={ref}
    >
      {children}
    </p>
  )
})

export default Text;