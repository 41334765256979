import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import NotificationProvider from "./components/notification/NotificationProvider";
import { ProvideAuth } from "./components/hooks/useAuth";

ReactDOM.render(
  <React.StrictMode>
    <NotificationProvider>
      <ProvideAuth>
        <App />
      </ProvideAuth>
    </NotificationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
