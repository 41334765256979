import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNotification } from '../notification/NotificationProvider';
import Button from '../button'
import Input from '../form/Input';
import useFirebase from '../hooks/useFirebase';
import useEventListener from '../hooks/useEventListener';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Navigation from '../navigation';
import Icon from "../icon/Icon";
import alignments from "./options/alignments.json";
import colors from "./options/colors.json";
import fonts from "./options/fonts.json";
import DropSelect, {DropSelectToolbar} from "../dropSelect/";
import Range from "../form/range"
import Textarea from "../form/Textarea";
import useFontStyle from "../hooks/useFontStyle";
import Modal from "../modal/";
import Player from "./player";
import {ProjectContext} from "./ProjectProvider";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const optionsFont = fonts;
const optionsColor = colors;
const optionsAlignment = alignments;
const optionsDefault = {
  content: "",
  font: {
    fontFamily: optionsFont[0].value,
    color: optionsColor[0].value,
    textAlign: optionsAlignment[0].value,
    fontWeight: optionsFont[0].weights[0].value,
    fontSize: 10,
    lineHeight: 0,
    letterSpacing: 0,
  }
}

const EditProject = () => {
  let { id } = useParams();
  const { firestore } = useFirebase();
  const [playModal, setPlayModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const {projects, isLoading} = useContext(ProjectContext);
  const [project, setProject] = useState(optionsDefault);
  const playerScreen = useFullScreenHandle();
  const fontStyle = useFontStyle({
    fontFamily: (project && project.font && project.font.fontFamily) || null,
    color: (project && project.font && project.font.color) || null,
    textAlign: (project && project.font && project.font.textAlign) || null,
    fontWeight: (project && project.font && project.font.fontWeight) || null,
    fontSize: (project && project.font && project.font.fontSize) || null,
    lineHeight: (project && project.font && project.font.lineHeight) || null,
    letterSpacing: (project && project.font && project.font.letterSpacing) || null,
  })
  const dispatch = useNotification();
  const projectRef = firestore.collection("projects").doc(id);
  let history = useHistory();

  const getReadTime = (speed) => {
    const wordsPerMinute = speed || 190; // Average case.
    let result;

    let textLength = (project && project.content && project.content.split(" ").length) || 0; // Split by words
    if(textLength > 0){
      let value = Math.ceil(textLength / wordsPerMinute);
      result = `~${value} min`;
    }
    return result;
  }

  const handleDelete = () => {
    // We redirect users instantly to avoid undefined stuff and flashing of
    // existing and not existing data because failioure to delete is very rare
    history.push(`/`);
    projectRef.delete().then(() => {
      dispatch({
        type: "SUCCESS",
        message: "Project successfully deleted!",
      });
  }).catch((error) => {
    dispatch({
      type: "ERROR",
      message: `Error deleting project: ${error}`,
    })
  });
  }

  const handleSave = () => {
    if (project.name && project.name !== "") {
      projectRef.update(project)
      .then(() => {
          dispatch({
            type: "SUCCESS",
            message: "Project successfully updated!",
          });
      })
      .catch((error) => {
          // The document probably doesn't exist.
          dispatch({
            type: "ERROR",
            message: `Error updating project: ${error}`,
          });
      });
    } else {
      dispatch({
        type: "ERROR",
        message: "Name of project can't be empty",
      });
    }
  }

  const handleSaveKey = (e) => {
    if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
      e.preventDefault();
      handleSave()
    }
  }

  const handleChangeFont = (value) => {
    if (value) {
      setProject(prevFont => ({
        ...prevFont,
        font: {
          ...(prevFont.font && prevFont.font),
          fontFamily: value,
          fontWeight: optionsFont.filter(item => item.value === value)[0].weights[0].value
        }
      }));
    }
  }

  const handleChange = (name, value) => {
    setProject(prevFont => ({
      ...prevFont,
      font: {
        ...(prevFont.font && prevFont.font),
        [name]: value
      }
    }));
  }

  const handleChangeContent = (value) => {
    setProject(prevproject => ({...prevproject, content: value}));
  }

  const reportChange = useCallback((state, handle) => {
    if (handle === playerScreen && !state) {
      setPlayModal(false);
    }
  }, [playerScreen]);

  useEffect(() => {
    if(!isLoading && projects){
      if(projects.filter((item) => item.id === id).length) {
        setProject( prevProject => ({...prevProject, ...projects.filter((item) => item.id === id)[0]}));
      }
    }
    return () => {
      setProject(optionsDefault)
      setDeleteName("");
    }
  }, [projects, isLoading, id])

  useEventListener('keydown', handleSaveKey)

  return (
    <>
    {project &&  (
      <>
        <Navigation style={{ justifyContent: 'flex-start' }}>
          <>
            <Button style={{width: 'auto', textTransform: 'uppercase', fontWeight: 500}} text="Your projects" color="secondary" to="/projects" />
            <Icon name="chevron_right" color="#fff" size="4.2rem" />
            <Input
              noSpacing
              icon="create"
              label="Project name"
              name="name"
              value={project.name || ""}
              onChange={(e) => setProject({...project, [e.target.name]: e.target.value})}
            />
            <div style={{fontSize: '1.8rem', margin: '0 1.6rem'}}>
              Reading time: {getReadTime()}
            </div>
            <Button
              style={{ marginLeft: 'auto'}}
              // text={`Save (${window.navigator.platform.match("Mac") ? "⌘" : "Ctrl"} + s)`}
              icon="save"
              onClick={handleSave}
              color="primary"
              circle
            />
            <Button
              style={{ marginLeft: '3.2rem'}}
              // text={`Save (${window.navigator.platform.match("Mac") ? "⌘" : "Ctrl"} + s)`}
              icon="delete"
              onClick={() => setDeleteModal(true)}
              color="danger"
              circle
            />
            <Button
              style={{marginLeft: '3.2rem'}}
              icon="play_arrow"
              onClick={() => {playerScreen.enter(); setPlayModal(true)}}
              color="primary"
              circle
            />
          </>
        </Navigation>
        {isLoading ? (
          <p style={{color: "#fff", fontSize: "2.1rem", textAlign: "center"}}>Loading...</p>
          ) : (
            <>
              <DropSelectToolbar>
                <DropSelect
                  label="Font"
                  onChange={(option) => handleChangeFont(option.value)}
                  options={optionsFont}
                  selected={project && project.font && project.font.fontFamily}
                  selectedDiv={(label, value) => (<div style={{fontFamily: value}}>{label}</div>)}
                  optionDiv={(label, value) => (<div style={{fontFamily: value}}>{label}</div>)}
                />
                <DropSelect
                  label="Color"
                  onChange={(option) => handleChange("color", option.value)}
                  options={optionsColor}
                  selected={project && project.font && project.font.color}
                  selectedDiv={(label, value) => (<><span style={{backgroundColor: value, width:'2.4rem', height: '2.4rem', borderRadius: '50%', marginRight: '1.0rem'}} /></>)}
                  optionDiv={(label, value) => (<><span style={{backgroundColor: value, width:'2.4rem', height: '2.4rem', borderRadius: '50%', border: '1px solid #3A3A3A', marginRight: '1.0rem'}} />{label}</>)}
                />
                <DropSelect
                  label="Align"
                  onChange={(option) => handleChange("textAlign", option.value)}
                  options={optionsAlignment}
                  selected={project && project.font && project.font.textAlign}
                  selectedDiv={(label, value) => (<><Icon name={`format_align_${value}`} color="#fff" size="3.2rem" /></>)}
                  optionDiv={(label, value) => (<><Icon name={`format_align_${value}`} color="#3A3A3A" size="3.2rem" style={{marginRight: '1.0rem'}} />{label}</>)}
                />
                <DropSelect
                  label="Thickness"
                  onChange={(option) => handleChange("fontWeight", option.value)}
                  options={project && project.font && project.font.fontFamily ? optionsFont.filter(item => item.value === project.font.fontFamily)[0].weights : null}
                  selected={project && project.font && project.font.fontWeight}
                  selectedDiv={(label, value) => (<div style={{fontWeight: value}}>{label}</div>)}
                  optionDiv={(label, value) => (<div style={{fontWeight: value}}>{label}</div>)}
                />
                <DropSelect
                  label="Size"
                  selectedLabel={
                    <span style={{width: '2.4rem'}}>
                      {project && project.font && project.font.fontSize ? project.font.fontSize : "N/A"}
                    </span>
                  }
                >
                  <Range
                    min="1"
                    max="100"
                    style={{margin: '1.6rem 3.2rem'}}
                    value={project && project.font && project.font.fontSize}
                    onChange={(value) => handleChange("fontSize", parseInt(value))}
                  />
                </DropSelect>
                <DropSelect
                  label="Line height"
                  selectedLabel={
                    <span style={{width: '2.4rem'}}>
                      {project && project.font && project.font.lineHeight ? project.font.lineHeight : "N/A"}
                    </span>
                  }
                >
                  <Range
                    min="0"
                    max="100"
                    style={{margin: '1.6rem 3.2rem'}}
                    value={project && project.font && project.font.lineHeight}
                    onChange={(value) => handleChange("lineHeight", parseInt(value))}
                  />
                </DropSelect>
                <DropSelect
                  label="Char spacing"
                  selectedLabel={
                    <span style={{width: '2.4rem'}}>
                      {project && project.font && project.font.letterSpacing ? project.font.letterSpacing : "N/A"}
                    </span>
                    }
                  >
                  <Range
                    min="0"
                    max="100"
                    style={{margin: '1.6rem 3.2rem'}}
                    value={project && project.font && project.font.letterSpacing}
                    onChange={(value) => handleChange("letterSpacing", parseInt(value))}
                  />
                </DropSelect>
              </DropSelectToolbar>

              <Modal
                darkmode
                isOpen={deleteModal}
                title={`DELETE PROJECT: ${project.name}`}
                onClose={() => {setDeleteModal(false); setDeleteName("")}}>
                <p style={{color: '#fff', fontSize: '1.6rem', marginBottom: '3.2rem'}}>
                  To delete this project, type it's name below
                </p>
                <Input
                  icon="delete"
                  label="Project name"
                  value={deleteName || ""}
                  onChange={(e) => setDeleteName(e.target.value)}
                />
                <Button
                  style={{marginTop: '3.2rem'}}
                  onClick={handleDelete}
                  color="danger"
                  text="DELETE FOREVER"
                  disabled={deleteName !== project.name}
                />

              </Modal>

              <div style={{minWidth: '50.0rem', maxWidth: '100%', margin: '3.2rem auto'}}>
                <Textarea
                  value={project.content || ""}
                  onChange={(e) => handleChangeContent(e.target.value)}
                  style={{...fontStyle}}
                />
              </div>
              {/* Player */}
              {
                <div style={{display: playModal ? "block" : "none"}}>
                  <FullScreen handle={playerScreen} onChange={reportChange}>
                    <Player text={project.content} font={project.font} isOpen={playModal} />
                  </FullScreen>
                </div>
              }
            </>
          )
        }
      </>
    )}
    </>
  )
}

export default EditProject;