import React, { useEffect, useRef } from "react";
import { useAuth } from "../hooks/useAuth";
import styling from "./Navigation.module.scss";
import Button from "../button";
import DropDown from "../dropDown/";

function Navbar({children, style}) {
  // Get auth state and re-render anytime it changes
  const auth = useAuth();
  const navbar = useRef(null);

  const handleScroll = () => {
    /**
     * If somehow the user scrolls down before the nav is rendered,
     * the reference will be empty, so className will not exist.
     * This is why the conditioning of the condition is necessary.
     */
    if (navbar.current && navbar.current.className) {
      if (window.scrollY > 20) {
        navbar.current.className = `${styling.navigation} ${styling.scroll}`;
      } else {
        navbar.current.className = styling.navigation;
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return(() => {
      window.removeEventListener("scroll", handleScroll);
    })
  }, [])

  return (
    <div ref={navbar} className={styling.navigation}>
        <div style={style && style} className={styling.left}>
          {children}
        </div>
        {/* {auth.user && (
          <Link to="/">Your scripts</Link>
          <Link to="/create" styling={{color: '#e53935'}}>Create</Link>
          <Link to="/import" styling={{color: '#02ff6c'}}>Import</Link>
          <Link to="/upload" styling={{color: '#0090FF'}}>Upload</Link>
        )} */}
        <div className={styling.right}>
          <DropDown direction='left' trigger={<Button icon="person" circle color="secondary" />}>
            <Button color="link" to="/account" text="Account"/>
            <Button color="link" onClick={() => auth.signout()} text="Logout"/>
          </DropDown>
        </div>
    </div>
  );
}

export default Navbar;