import React, {useState} from 'react';
import { useAuth } from "../hooks/useAuth";
import Input from '../form/Input';
import Button from "../button";
import styles from "./Auth.module.scss";

const SignUp  = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const auth = useAuth();

  const handleSignUp = () => {
    if(email && password) {
      auth.signup(email, password)
    }
  }

  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.header}>Create a new account</h1>
        <Input
          type="email"
          icon="person"
          label="E-mail"
          value={email || ""}
          onChange={(e) => setEmail(e.target.value)}
          autocomplete="email"
        />
        <Input
          type="password"
          icon="lock"
          label="Password"
          value={password || ""}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button text="Sign up" onClick={handleSignUp} color="primary" />
        <Button text="Forgot your password?" to="/forgot-password" color="link" />
        <div style={{marginTop: '3.0rem'}}>
          <Button text="Sign in" to="/signin" color="secondary" />
        </div>
      </div>
    </>
  );

}

export default SignUp;