import React from 'react'
import './App.scss';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import ForgotPassword from './components/auth/ForgotPassword';
import Projects from './components/project/';
import EditProject from './components/project/edit';
import PlayProject from './components/project/player';
import ProjectProvider from "./components/project/ProjectProvider";
import Account from "./components/account/"
import { useAuth } from "./components/hooks/useAuth";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";

const App = () => {
  const auth = useAuth();

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

  // Validates if the user is signed in
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      auth.user
        ? <div style={{padding: '3.2rem'}}><Component {...props} /></div>
        : <Redirect to='/signin' />
    )} />
  )

  // Redirect the user after or if signed in
  const RedirectAuth = ({ component: Component, ...rest }) => {
    let destinationPath = useQuery().get("destinationPath");
    return (
      <Route {...rest} render={(props) => (
        auth.user
          ? <Redirect to={destinationPath ? destinationPath : "/projects"} />
          : <Component {...props} />
      )} />
    )
  }

  return (
    // Wait until auth sets user to avoid double render
    auth.user !== null && (
      <Router>
        <div>
          <Switch>
            <RedirectAuth exact path='/' component={SignIn} />
            <RedirectAuth path='/signin' component={SignIn} />
            <RedirectAuth path='/signup' component={SignUp} />
            <RedirectAuth path='/forgot-passwor' component={ForgotPassword} />
            <PrivateRoute  path='/account' component={Account} />
            {/* Project provider needs auth validation because it gets clled before the private routes */}
            <ProjectProvider>
              <PrivateRoute path='/projects' component={Projects} />
              <PrivateRoute path='/edit/:id' component={EditProject} />
              <PrivateRoute path='/player/:id' component={PlayProject} />
            </ProjectProvider>
          </Switch>
        </div>
      </Router>
    )
  );
}

export default App;
