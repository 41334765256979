import React, { useState } from 'react';
import { useAuth } from "../hooks/useAuth";
import Input from '../form/Input';
import Button from "../button";
import styles from "./Auth.module.scss";

const ForgotPassword = () => {
  const [email, setEmail] = useState(null);
  const auth = useAuth();

  const handleLogin = () => {
    if(email) {
      auth.sendPasswordResetEmail(email)
    }
  }

  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.header}>Forgot Password</h1>
        <Input
          type="email"
          icon="person"
          label="E-mail"
          value={email || ""}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button text="Send reset email" onClick={handleLogin} color="primary" />
        <Button text="Sign in" to="/signin" color="link" />
        <div style={{marginTop: '3.0rem'}}>
          <Button text="Create a new account" to="/signup" color="secondary" />
        </div>
      </div>
    </>
  );

}

export default ForgotPassword;
