import React from 'react';
import Button from '../button';
import Icon from '../icon/Icon';
import style from './Empty.module.scss';

const Empty = ({text, btnText, onClick, to}) => {
  return (
    <div className={style.noContent}>
      <Icon color="#3A3A3A" size="7.2rem" name="notifications" />
      <p>{text}</p>
      {to ? (
        <Button to={to} text={btnText} color="primary" />
      ) : (
        <Button onClick={onClick} text={btnText} color="primary" />
      )}

    </div>
  )
}

export default Empty;