import React from 'react';
import styles from './Button.module.scss'
import { Link } from "react-router-dom";
import Icon from "../icon/Icon";

const Button = ({onClick, text, color, to, style, circle, icon, children, className, target, disabled}) => {

  return (
    <>
      {to ? (
        <Link
          className={`${className ? className : ""} ${disabled ? styles.disabled : ""} ${children ? "" : styles.btn} ${color ? styles[color] : ""} ${circle ? styles.btnCircle : ""}`}
          to={to}
          target={target ? target : ""}
          style={style && style}
          disabled={disabled && disabled}
        >{text && text} {children} {icon && <Icon name={icon} color="#fff" size="3.2rem" />} </Link>
      ) : (
        <button
          className={`${className ? className : ""} ${disabled ? styles.disabled : ""} ${children ? "" : styles.btn} ${color ? styles[color] : ""} ${circle ? styles.btnCircle : ""}`}
          onClick={onClick}
          style={style && style}
          disabled={disabled && disabled}
        >{text && text} {children} {icon && <Icon name={icon} color="#fff" size="3.2rem" />}</button>
      )}
    </>
  )
}

export default Button;
