import React, { useState } from 'react';
import { useAuth } from "../hooks/useAuth";
import Input from '../form/Input';
import Button from "../button";
import styles from "./Auth.module.scss";

const SignIn  = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const auth = useAuth();

  const handleLogin = () => {
    if(email && password) {
      auth.signin(email, password)
    }
  }

  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.header}>Login</h1>
        <Input
          type="email"
          icon="person"
          label="E-mail"
          value={email || ""}
          onChange={(e) => setEmail(e.target.value)}
          autocomplete="email"
        />
        <Input
          type="password"
          icon="lock"
          label="Password"
          value={password || ""}
          onChange={(e) => setPassword(e.target.value)}
          autocomplete="current-password"
        />
        <Button text="Sign in" onClick={handleLogin} color="primary" />
        <Button text="Forgot your password?" to="/forgot-password" color="link" />
        <div style={{marginTop: '3.0rem'}}>
          <Button text="Create a new account" to="/signup" color="secondary" />
        </div>
      </div>
    </>
  );

}

export default SignIn;
