import React, { useState, useEffect, useContext, createContext } from "react";
import { useNotification } from '../notification/NotificationProvider';
import useFirebase from "./useFirebase";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const { firebase } = useFirebase();
  const [user, setUser] = useState(null);
  const dispatch = useNotification();
  // This is used to redirect user back to app after any action we use it only for pass reset
  // can redeirect back to app or web: https://firebase.google.com/docs/auth/web/passing-state-in-email-actions
  var actionCodeSettings = {
    // After password reset, the user will be give the ability to go back
    // to this page.
    url: 'http://localhost:3000/', // change this to env
    handleCodeInApp: false,
  };

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = async (email, password) => {
    return await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(response => {
        setUser(response.user);
        dispatch({
          type: "SUCCESS",
          message: "You Have Successfully Logged in",
        })
        return response.user;
      }).catch((error) => {
        const errorMessage = error.message;
        dispatch({
          type: "ERROR",
          message: errorMessage,
        })
      });
  };

  const signup = async (email, password) => {
    return await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(response => {
        setUser(response.user);
        dispatch({
          type: "SUCCESS",
          message: "Account was created successfully",
        })
        return response.user;
      }).catch((error) => {
        const errorMessage = error.message;
        dispatch({
          type: "ERROR",
          message: errorMessage,
        })
      });
  };

  const signout = async () => {
    return await firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({
          type: "SUCCESS",
          message: "Signed out successfully",
        })
        setUser(false);
      }).catch(() => {
        dispatch({
          type: "ERROR",
          message: "Failed to sign put",
        })
      });
  };

  const sendPasswordResetEmail = async email => {
    return await firebase
      .auth()
      .sendPasswordResetEmail(email, actionCodeSettings)
      .then(() => {
        dispatch({
          type: "SUCCESS",
          message: "E-mail sent",
        })
        return true;
      }).catch(() => {
        dispatch({
          type: "ERROR",
          message: "Failed to send E-mail",
        })
      });
  };

  // const confirmPasswordReset = (code, password) => {
  //   return firebase
  //     .auth()
  //     .confirmPasswordReset(code, password)
  //     .then(() => {
  //       return true;
  //     });
  // };

  const credentials = (email, password) => {
    return firebase
      .auth
      .EmailAuthProvider.credential(
        email,
        password
      );
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });

    // Cleanup subscription on unmount
    // we are not passing in user so user gets set to false
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    // confirmPasswordReset
    credentials,
  };
}