import React from "react"
import styling from "./Range.module.scss";

const Range = ({min, max, value, onChange, style}) => {

  return (
    <div style={{...(style && style), display: 'flex', alignItems: 'center'}}>
      <input
        className={styling.slider}
        type="range"
        min={min}
        max={max}
        // range uses strings so we need to send string that later gets converted to number
        value={value && value.toString()}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

export default Range;