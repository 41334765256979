import React, { useState } from "react";
import { useNotification } from '../notification/NotificationProvider';
import Input from '../form/Input';
import Button from '../button'
import useFirebase from '../hooks/useFirebase';
import { useHistory } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";


const CreateScript = () => {
  const auth = useAuth();
  const uid = auth.user.uid;
  let history = useHistory();
  const dispatch = useNotification();
  const [name, setName] = useState(null);
  const { firestore } = useFirebase();

  const handleCreate = () => {
    // Add a new document in collection "cities"
    // scrips > id > name, content, author, colaboratorIds
    if(name) {
      firestore.collection('projects').add({
        name: name,
        author_uid: uid
      }).then((docRef) => {
        dispatch({
          type: "SUCCESS",
          message: "Script created, you are now in edit mode",
        });
        history.push(`/edit/${docRef.id}`);
      }).catch((error) => {
        dispatch({
          type: "ERROR",
          message: "Error creating document: ", error,
        })
      });
    } else {
      dispatch({
        type: "ERROR",
        message: "Please enter the name of your project",
      })
    }
  }

  return (
    <>
      <Input
        icon="create"
        label="Project name"
        value={name || ""}
        onChange={(e) => setName(e.target.value)}
      />
      <Button
        text="Create"
        onClick={handleCreate}
        color="primary"
      />
    </>
  )
}

export default CreateScript;