import { useContext, useState } from 'react';
import Button from '../button'
import Empty from "../empty";
import Navigation from '../navigation';
import style from "./Project.module.scss";
import {ProjectContext} from "./ProjectProvider";
import Create from "./create";
import Modal from "../modal/";

const Projects = () => {
  const [createModal, setCreateModal] = useState(false);
  const {projects, isLoading} = useContext(ProjectContext);

  return (
    <>
      <Navigation>
        <h1>Your projects</h1>
        <Button icon="add" onClick={() => setCreateModal(true)} color="primary" circle />
      </Navigation>

      <Modal
        darkmode
        isOpen={createModal}
        onClose={() => setCreateModal(false)}
        title="CREATE PROJECT"
      >
        <Create />
      </Modal>

      {isLoading ? (
        <div style={{fontSize: "1.8rem", textAlign: "center", color: '#fff'}}>
          Loading...
        </div>
      ) : (
        <>
          {projects.length === 0 ?
            <>
              <Empty
                text="Your projects will appear here"
                btnText="New project"
                onClick={() => setCreateModal(true)}
              />
            </>
          :
          <div className={style.list}>
            {projects.map((project) => (
              <Button key={project.id} to={`/edit/${project.id}`} className={style.listItem}>
                <h1>{project.name.substring(0, 20)}</h1>
                <p>{project.content && project.content.substring(0, 120)}</p>
              </Button>
            ))}
          </div>
          }
        </>
      )}
    </>
  )
}

export default Projects;