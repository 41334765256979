import React, {useState, useEffect, useRef} from 'react'
import styles from './Textarea.module.scss'

const Textarea = ({
  label,
  onChange,
  value,
  name,
  style,
}) => {
  const [textareaEmpty, setTextareaEmpty] = useState(true);
  const textareaContainerRef = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    value && value !== "" ? setTextareaEmpty(false) : setTextareaEmpty(true)
    return () => {
      setTextareaEmpty(true)
    }
  }, [value])

  const autoResize = (el, elContainer) => {
    // Get textarea height
    let offset = el.offsetHeight - el.clientHeight;
    el.style.height = 'auto';
    el.style.height = el.scrollHeight + offset + 'px';
    // Set container to textarea height
    elContainer.style.height = el.clientHeight + 'px'; // this fixes skipping effect when resizing.
  }

  useEffect(() => {
    if (textareaRef && textareaRef.current && textareaContainerRef && textareaContainerRef.current) {
      autoResize(textareaRef.current, textareaContainerRef.current);
    }
  }, [value, style]);

  return (
    <>
      <div
        className={`${styles.container} ${textareaEmpty ? styles.textareaEmpty : styles.textareaFilled}`}
        ref={textareaContainerRef}
      >
        <label className={styles.label}>
          <textarea
            ref={textareaRef}
            style={style && style}
            placeholder={label}
            className={`${styles.textarea}`}
            onChange={onChange}
            name={name}
            value={value || ""}
          />
          <span className={styles.labelText}>{label}</span>
        </label>
      </div>
    </>
  );
}

export default Textarea;
