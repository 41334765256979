import React, {useState, useEffect, useRef} from 'react';
import style from "./Player.module.scss";
import Text from "../text"
import useOnScreen from "../hooks/useOnScreen";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useDoubleTap } from "use-double-tap";
import useEventListener from '../hooks/useEventListener';

// const speeds = [90, 65, 40, 25, 15, 8, 6, 4, 2]; // 0-8
const speeds = [40, 35, 30, 25, 15, 10, 8, 6, 4, 2]; // 0-10


let defaultSettings = {
  isFlipped: false,
  isPlaying: false,
  scrollSpeed: 3,
  font: {},
}

/**
 * @param {text} text to scroll
 */
const Player = ({text, font, isOpen}) => {
  const topRef = useRef(null);
  const scrollerRef = useRef(null);
  const bottomRef = useRef(null);
  const scrollTimer = useRef(null); // we can save timer in useRef and pass it to child
  const hitTop = useOnScreen(topRef);
  const hitBottom = useOnScreen(bottomRef);
  const { height } = useWindowDimensions();
  const [position, setPosition] = useState(0);
  const [settings, setSettings] = useState(defaultSettings);

  const tapBind = useDoubleTap(() => {
    //double tap
    setSettings(oldSettings => ({...oldSettings, isFlipped: !oldSettings.isFlipped}))
  }, 300, {
    //onSingleTap: () => setSettings(oldSettings => ({...oldSettings, isFlipped: !oldSettings.isFlipped}))
  });

  const scrollHandler = event => {
		setPosition(event.currentTarget.scrollTop)
	}

  const scrollTo = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView(true)
    }
  }

  const jumpUp = () => {
		setPosition(currentPosition => currentPosition - 50)
	}

	const jumpDown = () => {
		setPosition(currentPosition => currentPosition + 50)
	}

  const handleKeyDown = (e) => {
    if (!isOpen) {
      return;
    }
    // Play Pause
    if (e.code === "Space") {
      e.preventDefault();
      setSettings(oldSettings => ({...oldSettings, isPlaying: !oldSettings.isPlaying}))
    }
    // Scroll up (up arrow)
    if (e.code === "ArrowUp") {
      e.preventDefault();
      if (settings.isFlipped) {
        !hitBottom && jumpDown();
      } else {
        !hitTop && jumpUp();
      }
    }
    // Scroll down (down arrow)
    if (e.code === "ArrowDown") {
      e.preventDefault();
      if (settings.isFlipped) {
        !hitTop && jumpUp();
      } else {
        !hitBottom && jumpDown();
      }
    }

    if (e.code === "ArrowLeft") {
      e.preventDefault();
      setSettings(oldSettings => ({...oldSettings, scrollSpeed: oldSettings.scrollSpeed === 1 ? oldSettings.scrollSpeed : oldSettings.scrollSpeed-1}))
    }

    if (e.code === "ArrowRight") {
      e.preventDefault();
      setSettings(oldSettings => ({...oldSettings, scrollSpeed: oldSettings.scrollSpeed === speeds.length ? oldSettings.scrollSpeed : oldSettings.scrollSpeed+1}))
    }
  }

  const getPercentage  = () => {
    if (scrollerRef.current) {
      let scrollerRefHeight = scrollerRef.current.scrollHeight - scrollerRef.current.clientHeight;
      let percentage = (scrollerRef.current.scrollTop / scrollerRefHeight) * 100
      return percentage.toFixed(2) > 99.30 ? 100 : percentage.toFixed(2);
    } else {
      return 0;
    }
  }

  useEventListener('keydown', handleKeyDown)

  useEffect(() => {
    /**
     *  Start/Stop & scroll speed
     *  Auto stop if normal and hits bottom or flipped and hits top
     */
    if ((settings && settings.isPlaying && settings.scrollSpeed && settings.isFlipped && !hitTop)
    || (settings && settings.isPlaying && settings.scrollSpeed && !settings.isFlipped && !hitBottom)
      ) {
      // useRef value stored in .current property
      scrollTimer.current = setInterval(() => {
        setPosition((pos) => settings.isFlipped ? pos - 0.5  : pos + 0.5)
      }, speeds[settings.scrollSpeed]);
    } else {
      clearInterval(scrollTimer.current);
    }

    // clear on component unmount
    return () => {
      clearInterval(scrollTimer.current);
    };
  }, [settings, hitBottom, hitTop]);

  useEffect(() => {
    // If scroll position is at the beginning of text then flipping the text should not effect this
    // when flipping and at start of text: scroll to bottom
    if(settings.isFlipped && hitTop) {
      scrollTo(bottomRef);
    }
    // when unflipping and at start of text: scroll to top
    if(!settings.isFlipped && hitBottom) {
      scrollTo(topRef);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.isFlipped])

  // Scroll text
  useEffect(() => {
    scrollerRef.current.scroll({
      top: position,
    })
  }, [position]);

  // Allow users to scroll text while its autoscrolling
  // by setting posiion to user scrolled position
  useEffect(() => {
    if(scrollerRef) {
      let scrollRefCurrent = scrollerRef.current;
      scrollRefCurrent.addEventListener('scroll', scrollHandler)
      return () => scrollRefCurrent.removeEventListener('scroll', scrollHandler);
    }
	}, []);

  useEffect(() => {
    if((hitTop && settings.isFlipped) || (hitBottom && !settings.isFlipped)) {
      setSettings(oldSettings => ({...oldSettings, isPlaying: false}))
    }
  }, [hitTop, hitBottom, settings.isFlipped]);

  useEffect(() => {
    if(!isOpen) {
      setSettings(oldSettings => ({...oldSettings, isPlaying: false}))
    }
  }, [isOpen])

  // Debugging
  // console.log('position', position);
  // console.log('hitTop', hitTop);
  // console.log('hitBottom', hitBottom);


  // 1 - if on top and flips we must go to bottomRef (Done)
  // 2 - must stop inscrementing scroll position when reaching top or bottom

  return(
    <div {...tapBind}>
      <div className={style.statusBar}>
        <ul>
          <li>
            Speed (left - right): {settings.scrollSpeed}
          </li>
          <li>
            Scrolling (space): {settings.isPlaying ? "Yes" : "No"}
          </li>
          <li>
            Mirror (click): {settings.isFlipped ? "Yes" : "No"}
          </li>
          <li>
            Percentage: {settings.isFlipped ? Math.abs(getPercentage() - 100).toFixed(2) : getPercentage()}%
          </li>
        </ul>
      </div>
      <div ref={scrollerRef} className={style.container}>
        <div ref={topRef} style={{position: 'absolute', top:0}} />
        <Text
          ref={scrollerRef}
          isFlipped={settings.isFlipped}
          style={{
            ...(height && {paddingTop: `${height / 1.1}px`, paddingBottom: `${height / 2}px`})
          }}
          {...font}
        >
          {text}
        </Text>
        <div ref={bottomRef} />
      </div>
    </div>
  )
}

export default Player;