import React, {useState, useEffect, useRef} from 'react';
import styling from "./DropSelect.module.scss";
import useOnClickOutside from "../hooks/useOnClickOutside";

export const DropSelectToolbar = ({children, style}) => {
  return (
    <div className={styling.toolbarContainer} style={style ? style : {}}>
      {children}
    </div>
  );
}

/**
 * This is an advanced selected component that allows you to have rendered values for better UX
 * Normaly you have text as label and you pass the value to the backend, this components allows you
 * to display/render the value to the user for example this can be used to display fonts, font colors, font weights
 * to the user, this is very usefull and makes selection much more interactive.
 */
const DropSelect = ({
  onChange,
  options,
  label,
  selected,
  selectedDiv,
  optionDiv,
  children,
  selectedLabel
}) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const listRef = useRef()
  useOnClickOutside(listRef, () => setOpen(false));

  const handleChange = (option) => {
    onChange(option)
    setOpen(false)
  }

  useEffect(() => {
    if(selected) {
      setSelectedOption(options.filter((option) => option.value === selected)[0]) // filter selected option using selected option value
    }
    return () => {
      setSelectedOption(null);
    }
  }, [selected])

  return (
    <>
      {!children && (
        <div ref={listRef} className={styling.container}>
          <div className={`${styling.selected} ${open ? styling.listOpen : ""}`} onClick={() => setOpen(!open)}>
            {selectedOption ? (
              <>
                {label ? <span className={styling.label}>{`${label}:`}</span> : ""}
                {selectedDiv ? selectedDiv(selectedOption.label, selectedOption.value) : selectedOption.label}
              </>
            ) : <><span className={styling.label}>{label}</span>-</>}
          </div>
          <div className={`${styling.list} ${open ? styling.listOpen : ""}`}>
            {options && options.map((option) => (
              <div key={option.value} className={styling.listItem} onClick={() => handleChange(option)}>
                {optionDiv ? optionDiv(option.label, option.value) : option.label}
              </div>
            ))}
          </div>
        </div>
      )}
      {children && (
        <div ref={listRef} className={styling.container}>
          <div className={`${styling.selected} ${open ? styling.listOpen : ""}`} onClick={() => setOpen(!open)}>
            {label ? <span className={styling.label}>{`${label}:`}</span> : ""}
            {selectedLabel ? selectedLabel : ""}
          </div>
          <div className={`${styling.list} ${open ? styling.listOpen : ""}`}>
            {children}
          </div>
        </div>
      )}
    </>
  )
}

export default DropSelect;