import React, {useEffect} from 'react';
import styles from "./Modal.module.scss";

const Modal = ({isOpen, onClose, children, style, darkmode, title}) => {

  useEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if(isOpen) {
      // Prevent scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scrolling when component unmounts
      document.body.style.overflow = "visible"
    }
  }, [isOpen])

  return (
    <>
      <div
        style={{
          ...style,
          ...(isOpen && {display: 'block'}),
          ...(darkmode && {backgroundColor: '#1f1f1f', border: '1px solid #2b2b2b'})
        }}
        className={styles.modal}
      >
        {title && <h1 className={styles.title}>{title}</h1>}
        {children}
      </div>
      {isOpen && (
        <div onClick={() => onClose()} className={styles.backdrop} />
      )}
    </>
  )
}

export default Modal;